import { useEffect } from "react";
import Router from "next/router";
import { useUser } from "@auth0/nextjs-auth0/client";

const COMPANY_ID_WHATNOT = 35;

function Home() {
  const { user } = useUser();

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.company_id === COMPANY_ID_WHATNOT) {
      Router.push("/aggregates/focus-time");
    } else {
      Router.push("/team");
    }
  }, [user]);

  return <></>;
}

export default Home;
